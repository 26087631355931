#container_loader{
    width:100%;
    height: 100vh;
    position: fixed;
    background: white;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    z-index: 998;
}
.loading-title{
    position: relative;
    animation: 500ms loaderTextAnimation ease-in;
}
.loading-title > span{
    font-size: 40px;
    font-weight: bold;
    font-family: Helvetica !important;
    color: #084a7a;
}
.loading-image{
    width: 100px;
    height:100px;
    position: relative;
    animation: 800ms loaderLogoAnimation ease-in-out;
}
.loading-image > img{
    width: 100%;
    height:100%;
    animation: 2s rotate ease-in-out infinite;
    transform: scale(1.2);
    transform-origin: 50% 50%;
    opacity: .4;
}
.removeLoaderAnimation{
    animation: 300ms loaderOut ease-out;
}
@keyframes loaderTextAnimation {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }

}
@keyframes loaderLogoAnimation {
    0% {
        transform: translateY(200px);
        opacity: 0;
    }
    75% {
        transform: translateY(200px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }

}
@keyframes rotate {
    0% {
        transform: scale(1);
        opacity: .4;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: .4;
    }
}
@keyframes loaderOut {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(0);
    }
}